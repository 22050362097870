import React from 'react';
import styled from 'styled-components';
import LoginImage from '../assets/login.png';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${LoginImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  width: 29%;
  height: 70%;
  padding: 30px;
  background: rgba(111, 111, 111, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 0.1px solid #777777;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  text-align: center;
  color: #eee;
  margin-bottom: 15%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Label = styled.label`
  width: 90%;
  font-size: 1rem;
  color: #eee;
  margin-bottom: 5px;
  text-align: left;
`;

const Input = styled.input`
  border: 0.1px solid #a8a8a8;
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: none;
  border-radius: 8px;
  background: transparent;
  font-size: 1rem;
  color: #fff;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  margin-top: 5%;
  width: 50%;
  padding: 10px;
  background: transparent;
  color: #fff;
  border: 0.1px solid #aaa;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9370db;
    border: none;
  }
`;

export default function LoginPage() {
  return (
    <Container>
      <LoginBox>
        <Title>Login</Title>
        <Form>
          <Label htmlFor="id">ID</Label>
          <Input id="id" type="text" required />
          <Label htmlFor="password">Password</Label>
          <Input id="password" type="password" required />
          <Button type="submit">Login</Button>
        </Form>
      </LoginBox>
    </Container>
  );
}