import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.header`
  background-color: #000;
  color: #fff;
  padding: 20px 50px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  color: #9370db;
`;

const LeftNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const RightNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  transition: color 0.3s;

  &:hover {
    color: #9370db;
  }
`;

const Button = styled.button`
  background: none;
  border: 0.5px solid #aaa;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #9370db;
    color: #fff;
    border-color: transparent;
  }
`;

export default function Header() {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/Signup');
  };

  const handleLoginClick = () => {
    navigate('/Login');
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>DURUMI</Title>
        <LeftNav>
          <NavLink href="https://sssssss51.github.io/portfolio/portfolio.html" target="_blank" rel="noopener noreferrer">Portfolio</NavLink>
          <NavLink href="https://sssssss51.github.io/" target="_blank" rel="noopener noreferrer">sssssss51</NavLink>
          <NavLink href="https://durumi.comon.kr/phpmyadmin/" target="_blank" rel="noopener noreferrer">DB</NavLink>
          <NavLink href="https://khs.comon.kr/" target="_blank" rel="noopener noreferrer">khs</NavLink>
        </LeftNav>
      </TitleWrapper>
      <RightNav>
        <Button onClick={handleSignUpClick}>Sign Up</Button>
        <Button onClick={handleLoginClick}>Log In</Button>
      </RightNav>
    </Container>
  );
}