import React, { useState } from 'react';
import styled from 'styled-components';
import Folder from './Folder.jsx';
import Modal from './Modal.jsx';
import WebsImage from '../../assets/webs.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  background-image: url(${WebsImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
`

const Title = styled.h1`
  margin-bottom: 5%;
  color: #eee;
`;

const FoldersBox = styled.div`
  display: flex;
  gap: 30px;
`;

export default function Webs() {
  const [selectedFolder, setSelectedFolder] = useState(null);
  
  const websites = {
    RN: [
      { name: 'React Native', description: 'Learn about React Native.', url: 'https://reactnative.dev/' },
      // RN 관련 웹사이트들을 추가하세요
    ],
    IOS: [
      { name: 'Apple Developer', description: 'Resources for Apple developers.', url: 'https://developer.apple.com/' },
      // iOS 관련 웹사이트들을 추가하세요
    ],
    UI: [
      { name: 'Dribbble', description: 'Discover the world’s top designers & creatives.', url: 'https://dribbble.com/' },
      // UI 관련 웹사이트들을 추가하세요
    ],
    OTHERS: [
      { name: 'Google', description: 'Search the world\'s information.', url: 'https://www.google.com' },
      // 기타 웹사이트들을 추가하세요
    ],
  };

  return (
    <Container>
      <Title>Useful Websites</Title>
      <FoldersBox>
        <Folder title="RN" onClick={() => setSelectedFolder('RN')} />
        <Folder title="iOS" onClick={() => setSelectedFolder('IOS')} />
        <Folder title="UI" onClick={() => setSelectedFolder('UI')} />
        <Folder title="OTHERS" onClick={() => setSelectedFolder('OTHERS')} />
      </FoldersBox>
      {selectedFolder && (
        <Modal 
          websites={websites[selectedFolder]} 
          onClose={() => setSelectedFolder(null)} 
        />
      )}
    </Container>
  );
}