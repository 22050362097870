import React from 'react';
import Footer from '../../components/Footer.jsx';
import Header from '../../components/Header.jsx';
import Hero from './Hero.jsx';

export default function HomePage() {
  return (
    <>
      <Header />
      <Hero />
      <Footer />
    </>
  );
};