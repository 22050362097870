import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage/HomePage.jsx';
import { createGlobalStyle } from 'styled-components';
import LoginPage from './components/Login.jsx';   
import SignupPage from './components/Signup.jsx';   
import WebsPage from './pages/webs/Webs.jsx';  

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: scroll;
  }

  body {
    margin: 0;
    padding: 0;
  }
`;

export default function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/Signup" element={<SignupPage />} />
          <Route path="/Webs" element={<WebsPage />} />
        </Routes>
      </Router>
    </>
  );
}