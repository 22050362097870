import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { SlDrop } from 'react-icons/sl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
  padding: 20px;
  background: transparent;
`;

const Location = styled.p`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const TempWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TempText = styled.h2`
  font-size: 4rem;
  margin-right: 15px;
`;

const WeatherImg = styled.img`
  width: 7rem;
  height: 7rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const MaxMin = styled.p`
  font-size: 1.2rem;
  margin-right: 20px;
`;

const HumidityText = styled.p`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: 0,
      temp_max: 0,
      temp_min: 0,
      humidity: 0,
      desc: '',
      icon: '',
      loading: true,
    };
  }

  componentDidMount() {
    const cityName = 'Yongin';
    const apiKey = process.env.REACT_APP_WEATHER_KEY;
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}`;

    axios
      .get(url)
      .then((responseData) => {
        const data = responseData.data;
        this.setState({
          temp: data.main.temp,
          temp_max: data.main.temp_max,
          temp_min: data.main.temp_min,
          humidity: data.main.humidity,
          desc: data.weather[0].description,
          icon: data.weather[0].icon,
          loading: false,
        });
      })
      .catch((error) => console.log(error));
  }

  render() {
    const imgSrc = `https://openweathermap.org/img/wn/${this.state.icon}@2x.png`;

    if (this.state.loading) {
      return <p>Loading</p>;
    } else {
      return (
        <Container>
          <Location>용인</Location>
          <TempWrapper>
            <TempText>{(this.state.temp - 273.15).toFixed(0)}°</TempText>
            <WeatherImg src={imgSrc} alt="Weather Icon" />
          </TempWrapper>
          <InfoWrapper>
            <InfoRow>
              <MaxMin>
                {(this.state.temp_max - 273.15).toFixed(0)}° / {(this.state.temp_min - 273.15).toFixed(0)}°
              </MaxMin>
              <HumidityText>
                <SlDrop size="20px" style={{ marginRight: '5px' }} />
                {this.state.humidity}%
              </HumidityText>
            </InfoRow>
          </InfoWrapper>
        </Container>
      );
    }
  }
}

export default Weather;