import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import heroImage from '../../assets/hero.png';
import Weather from './Weather.jsx'; 

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 0 50px;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  height: 100%;
  padding-bottom: 50px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BlurBox = styled.div`
  width: 55%;
  height: ${({ variant }) => (variant === 'large' ? '30%' : '12%')};
  background: ${({ variant }) => (variant === 'large' ? 'rgba(102, 91, 143, 0.2)' : 'rgba(102, 91, 143, 0.2)')};
  -webkit-backdrop-filter: blur(calc(100vw * (5 / 1600)));
  backdrop-filter: blur(calc(100vw * (5 / 1600)));
  border-radius: 8px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: background 0.3s ease, padding-right 0.3s ease;
  font-size: 1.7rem;
  position: relative;

  &:not([variant='large']):hover {
    outline: 1px solid #ccc;
    padding-right: 100px;
    cursor: pointer;
  }

  &:not([variant='large']):hover::after {
    content: '→';
    position: absolute;
    margin-top: 2%;
    right: 30px;
    font-size: 3rem;
    transition: right 0.3s ease;
  }
`;

const Heading = styled.h1`
  font-size: 5rem;
  margin: 0 0 30px 0;
  line-height: 1.2;
`;

const SubHeading = styled.p`
  font-size: 1.3rem;
  margin: 20px 0;
  color: #e2e2e2;
`;

const Hr = styled.hr`
  width: 90%;
  border: 0.5px solid #b7b7b7;
  margin: 5px 0;
`;

export default function Hero() {
  const navigate = useNavigate();

  const handleWebsClick = () => {
    navigate('/Webs');
  };

  return (
    <Container>
      <LeftSection>
        <Heading>
          Space dust World
        </Heading>
        <Hr />
        <SubHeading>
          Insignificant yet sublime space dust revels in its fleeting youth
        </SubHeading>
      </LeftSection>
      <RightSection>
        <BlurBox variant="large">
          <Weather />
        </BlurBox>
        <BlurBox onClick={handleWebsClick}>Web Directory</BlurBox>
        <BlurBox>Box 3</BlurBox>
      </RightSection>
    </Container>
  );
}