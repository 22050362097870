import React from 'react';
import styled from 'styled-components';
import SignupImage from '../assets/signup.png';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${SignupImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignupBox = styled.div`
  width: 29%;
  height: 75%;
  padding: 30px;
  background: rgba(111, 111, 111, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 0.1px solid #777777;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  text-align: center;
  color: #eee;
  margin-bottom: 10%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Label = styled.label`
  width: 98%;
  font-size: 1rem;
  color: #eee;
  margin-bottom: 5px;
  text-align: left;
`;

const Input = styled.input`
  border: 0.1px solid #a8a8a8;
  width: ${props => props.id === 'year' ? '30%' : '100%'};
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: none;
  border-radius: 8px;
  background: transparent;
  font-size: 1rem;
  color: #fff;

  &::placeholder {
    color: #ccc;
    font-size: 80%;
  }

  &:focus {
    outline: none;
  }
`;

const Select = styled.select`
  border: 0.1px solid #a8a8a8;
  width: 30%;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: none;
  border-radius: 8px;
  background: transparent;
  font-size: 0.9rem;
  color: #fff;

  &:focus {
    outline: none;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DateSelect = styled(Select)`
  width: 30%;
`;

const Button = styled.button`
  margin-top: 5%;
  width: 50%;
  padding: 10px;
  color: #fff;
  border: 0.5px solid #aaa;
  border-radius: 25px;
  background: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9370db;
    border: none;
  }
`;

export default function SignupPage() {
  return (
    <Container>
      <SignupBox>
        <Title>Sign Up</Title>
        <Form>
          <Label htmlFor="name">Name</Label>
          <Input id="name" type="text" required />
          
          <Label htmlFor="age">Date of Birth</Label>
          <DateWrapper>
            <Input id="year" type="text" placeholder="Year (2)" maxLength="2" required />
            <Select id="month" required>
              <option value="">Month</option>
              {[...Array(12).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </Select>
            <DateSelect id="day" required>
              <option value="">Day</option>
              {[...Array(31).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </DateSelect>
          </DateWrapper>
          
          <Label htmlFor="phone">Phone Number</Label>
          <Input id="phone" type="tel" required />
          
          <Label htmlFor="id">ID</Label>
          <Input id="id" type="text" required />
          
          <Label htmlFor="password">Password</Label>
          <Input id="password" type="password" placeholder="8자 이상 입력해주세요" required />
          
          <Button type="submit">Sign Up</Button>
        </Form>
      </SignupBox>
    </Container>
  );
}