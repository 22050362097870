import React from 'react';
import styled from 'styled-components';

const FolderDiv = styled.div`
  width: 120px;
  height: 80px;
  background-color: rgba(128, 121, 151, 0.2);
  -webkit-backdrop-filter: blur(calc(100vw * (5 / 1600)));
  backdrop-filter: blur(calc(100vw * (5 / 1600)));
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border: 0.1px solid #ccc;
  }
`;

const FolderTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  transition: transform 0.2s ease-in-out;

  ${FolderDiv}:hover & {
    transform: translateY(5px);
  }
`;

export default function Folder({ title, onClick }) {
  return (
    <FolderDiv onClick={onClick}>
      <FolderTitle>{title}</FolderTitle>
    </FolderDiv>
  );
}