import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ModalContent = styled.div`
  background-color: #bbb;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
`;

const WebsiteList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WebsiteCard = styled.div`
  padding: 10px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ddd;
  }
`;

const WebsiteTitle = styled.h3`
  margin: 0;
  color: #293bc0;
`;

const WebsiteDescription = styled.p`
  margin: 5px 0;
  color: #666;
`;

const WebsiteURL = styled.a`
  margin: 5px 0;
  color: #293bc0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default function Modal({ websites, onClose }) {
  const handleCardClick = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  return (
    <Overlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <WebsiteList>
          {websites.map((website, index) => (
            <WebsiteCard key={index} onClick={() => handleCardClick(website.url)}>
              <WebsiteTitle>{website.name}</WebsiteTitle>
              <WebsiteDescription>{website.description}</WebsiteDescription>
              <WebsiteURL href={website.url} onClick={(e) => e.stopPropagation()}>
                {website.url}
              </WebsiteURL>
            </WebsiteCard>
          ))}
        </WebsiteList>
      </ModalContent>
    </Overlay>
  );
}