import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 30px 0;
  bottom: 0;
  width: 100%;
`;

export default function Footer() {
  return (
    <Container>
      Designed and Developed by hs101 &copy; 2024. &nbsp;&nbsp; Human beings are space dust.
    </Container>
  );
};